<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <form class="row justify-content-between">
          <div class="col-md-2">
            <v-select
              label="title"
              required
              v-model="perPage"
              :options="perPageOptions"
              @input="setPerPageSelected"
              :clearable="false"
            />
          </div>
          <div class="col-md-4">
            <input
              type="search"
              class="d-inline-block form-control"
              placeholder="Pesquisar..."
              v-model="filter"
            />
          </div>
        </form>
      </div>

      <b-table
        :sticky-header="true"
        :no-border-collapse="true"
        class="position-relative"
        :items="managers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Nenhum registro encontrado"
        empty-filtered-text="Nenhum registro encontrado"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        striped
        hover
      >
        <template #cell(created_at)="data">
          {{ data.item.created_at | datePT }}
        </template>

        <template #cell(name)="data">
          <div
            class="d-flex-left"
            v-b-tooltip.hover.top="data.item.active ? 'Ativo' : 'Inativo'"
          >
            <i
              style="font-size: 0.7rem"
              class="bi bi-circle-fill mr-50"
              :class="data.item.active ? 'text-success' : 'text-danger'"
            ></i>
            <span>
              {{ data.item.name }}
            </span>
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="d-flex-between">
            <feather-icon
              :id="`customer-${data.item.uuid}-delete-icon`"
              icon="TrashIcon"
              class="cursor-pointer cursor text-danger"
              size="16"
              @click="confirmDelete(data.item.uuid)"
            />

            <feather-icon
              :id="`customer-${data.item.uuid}-edit-icon`"
              icon="EditIcon"
              class="cursor-pointer cursor"
              size="16"
              @click="openModalEditCardNetwork(data.item.uuid)"
            />
          </div>
        </template>
      </b-table>

      <div class="mx-2 mb-2 mt-2 paginate-area">
        <div class="row">
          <div
            class="d-flex align-items-center justify-content-center justify-content-sm-start col-md-6"
          >
            <span class=""> Foram encontrados {{ totalRows }} resultados </span>
          </div>
          <div
            class="col-md-6 d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              :limit="10"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="my-1"
              @input="updatePage()"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </div>
    </b-card>

    <b-modal
      ref="modal-create-card-brand"
      modal-class="modal-create"
      :title="titleModal"
      centered
      hide-footer
      size="sm"
    >
      <form @submit.prevent="submitCreate">
        <div class="row">
          <div class="col-md-12">
            <label>
              <i class="text-danger bi bi-record-circle"></i>
              Nome
            </label>
            <div class="form-group mb-0">
              <input type="text" class="form-control" v-model="form.name" :class="{ 'is-invalid': $v.form.name.$error }">
            </div>
          </div>
        </div>
        <div class="form-row justify-content-between modal-footer-custom">
          <div class="col-md-4 col-12">
            <div class="form-group">
              <v-select
                label="title"
                :searchable="false"
                item-text="title"
                item-value="code"
                placeholder="Status"
                v-model="form.active"
                :options="optionsStatus"
                :class="{ 'is-invalid': $v.form.active.$error }"
              />
            </div>
          </div>
          <div class="col-md-6 col-12 d-flex-between">
            <button
              type="button"
              @click="closeModalCardNetwork"
              class="btn btn-light float-left"
            >
              Fechar
            </button>
            <button :disabled="submited" class="btn btn-success" type="submit">
              <div v-if="submited">
                <b-spinner small variant="light" />
              </div>
              <div v-else>{{ textSubmit }}</div>
            </button>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BBadge,
  BPagination,
  BSpinner,
  BCardText,
  BModal,
  BFormCheckbox,
  BAvatar,
  BAvatarGroup,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";
import { required } from "vuelidate/lib/validators";
import { Money } from "v-money";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BCard,
    BTable,
    BBadge,
    BPagination,
    BSpinner,
    BCardText,
    vSelect,
    BModal,
    ButtonsActionsFooter,
    BFormCheckbox,
    Money,
    BAvatar,
    BAvatarGroup,
  },
  data() {
    return {
      cardNetworkUuid: "",
      money: {
        decimal: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      optionsStatus: [
        { code: 1, title: "Ativo" },
        { code: 0, title: "Inativo" },
      ],
      optionsProductCategories: [],
      optionsUsers: [],
      optionsUnits: [],
      submited: false,
      textSubmit: "Cadastrar",
      titleModal: "Cadastrar Bandeira do cartão",
      managers: [],
      filter: null,
      filterOn: [],
      currentPage: 1,
      perPage: 100,
      totalRows: 0,
      rowsTable: 0,
      perPageOptions: [
        {
          title: "100",
          value: 100,
        },
        {
          title: "200",
          value: 200,
        },
      ],
      tableColumns: [
        {
          key: "name",
          label: "Nome",
        },
        {
          key: "created_at",
          label: "Cadastrado",
          class: "text-center",
          thClass: "text-center",
          thStyle: "width: 190px",
        },
        {
          key: "actions",
          label: "",
          thClass: "text-center td-actions",
          thStyle: "width: 80px",
        },
      ],
      form: {
        name: "",
        active: "",
      },
    };
  },
  methods: {
    closeModalCardNetwork() {
      this.cardNetworkUuid = "";
      this.$refs["modal-create-card-brand"].hide();
    },

    openModalEditCardNetwork(uuid) {
      this.textSubmit = "Salvar";
      this.titleModal = "Editar Bandeira do cartão";
      this.cardNetworkUuid = uuid;

      this.$store
        .dispatch("CardNetwork/show", this.cardNetworkUuid)
        .then((res) => {
          this.form = res;
          this.$refs["modal-create-card-brand"].show();
        });
    },

    clearForm() {
      this.form = {
        name: "",
        active: "",
      };

      this.cardNetworkUuid = "";
    },
    
    submitCreate() {
      this.$v.form.$touch();

      if (!this.$v.form.$error) {
        this.submited = true;

        if (this.cardNetworkUuid) {
          this.$store
            .dispatch("CardNetwork/update", {
              data: this.form,
              uuid: this.cardNetworkUuid,
            })
            .then(() => {
              this.notify(
                "Operação realizada com sucesso!",
                "UserCheckIcon",
                "success",
                ""
              );

              this.getData();
              this.$v.form.$reset;
              this.$refs["modal-create-card-brand"].hide();
            })
            .finally(() => {
              this.submited = false;
            });
        } else {
          this.$store
            .dispatch("CardNetwork/create", this.form)
            .then(() => {
              this.notify(
                "Operação realizada com sucesso!",
                "UserCheckIcon",
                "success",
                ""
              );

              this.getData();
              this.$v.form.$reset;
              this.$refs["modal-create-card-brand"].hide();
            })
            .finally(() => {
              this.submited = false;
            });
        }
      }
    },
    setPerPageSelected(obj) {
      this.perPage = obj.value;
      this.getData();
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    confirmDelete(uuid) {
      this.$swal({
        title: "Tem certeza?",
        text: "Só será possível deletar caso o item não esteja sendo usado.",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero deletar!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("CardNetwork/delete", { uuid: uuid })
            .then(() => {
              this.managers = [];
              this.getData();
              this.$swal({
                icon: "success",
                title: "Deletado!",
                text: "Operaçaõ realizada com sucesso.",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
        }
      });
    },
    updatePage() {
      this.getData();
    },
    async getData() {
      this.$store
        .dispatch("CardNetwork/all", {
          currentPage: this.currentPage,
          perPage: this.perPage,
        })
        .then((res) => {
          if (res.data.length) {
            this.managers = res.data;
            this.dataItems = res.data;
            this.rowsTable = res.data.length;
            this.totalRows = res.meta.total;
            this.currentPage = res.meta.current_page;
          }
        });
    },
    getFirstLetters(inputString) {
      const words = inputString.split(" ");
      const firstLetters = words.map((word) => word.charAt(0).toUpperCase());
      return firstLetters.join("");
    },
  },
  validations: {
    form: {
      name: {
        required,
      },
      active: {
        required,
      },
    },
  },
  mounted() {
    this.$root.$on("open:modal-create-card-brand", () => {
      this.clearForm();
      this.$refs["modal-create-card-brand"].show();
    });

    this.$root.$on("bv::modal::hidden", () => {
      this.clearForm();
    });

    this.getData();
  },
  beforeDestroy() {
    this.$root.$off("open:modal-create-card-brand");
  },
};
</script>
